import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import DownloadCard from "../UI/DownloadCard";
import { ContentItem } from "../types/MembershipCourses.type";
import { convertNewlinesToBreaks } from "../../../utils/utils";
import BunnyVideoPlayer from "../../BunnyVideoPlayer";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import ArrowRight18 from "../../../assets/icons/ArrowRight18";
import ArrowLeft18 from "../../../assets/icons/ArrowLeft18";

type RightPanelProps = {
  currentContentDisplay: ContentItem;
  isLastContent: boolean;
  handleMoveToNextContent: () => void;
};

const RightPanel = ({
  currentContentDisplay,
  isLastContent,
  handleMoveToNextContent,
}: RightPanelProps) => {
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const handleOpenMenu = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("menu", "1");
    navigate(currentUrl.pathname + currentUrl.search, { replace: true });
  };

  return (
    <div className="w-full md:w-2/3">
      <Box p="25px 30px" borderBottomLeftRadius="20px">
        <Heading fontWeight="900" className="!text-22 md:!text-26" mb="20px">
          {currentContentDisplay.title}
        </Heading>

        {/* Video section */}
        {currentContentDisplay.videoSrc && (
          <Box>
            <BunnyVideoPlayer iframeClassName="md:h-[45vh] xl:h-[65vh] h-[100%]" src={currentContentDisplay.videoSrc} width="100%"/>
          </Box>
        )}

        {/* Menu and Next buttons */}
        {isMobile && (
          <Flex
            mt="18px"
            mb="16px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center" onClick={handleOpenMenu}>
              <ArrowRight18 />
              <Text
                fontWeight="500"
                fontSize="14px"
                lineHeight="14px"
                ml="10px"
              >
                Menu
              </Text>
            </Flex>

            {!isLastContent && (
              <Flex alignItems="center" onClick={handleMoveToNextContent}>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="14px"
                  mr="10px"
                >
                  Next
                </Text>
                <ArrowLeft18 />
              </Flex>
            )}
          </Flex>
        )}

        {/* HTML section */}
        {currentContentDisplay.htmlSrc && (
          <Box>
            <div
              className="mt-4 rounded-lg border-[1px] border-[#EAECF0] bg-white p-4 md:mt-9"
              dangerouslySetInnerHTML={{
                __html: convertNewlinesToBreaks(currentContentDisplay.htmlSrc),
              }}
            />
          </Box>
        )}

        {/* Material section */}
        {currentContentDisplay.downloadSrc && (
          <div className="flex flex-col items-center justify-center gap-[7px] md:mt-5 md:flex-row md:items-start md:justify-start">
            {currentContentDisplay.downloadSrc.map((material, index) => (
              <DownloadCard key={index} num={index + 1} url={material} />
            ))}
          </div>
        )}
      </Box>
    </div>
  );
};

export default RightPanel;