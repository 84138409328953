import clsx from "clsx";
import { HashLink } from "react-router-hash-link";
import { ROUTES } from "../../../constants";
import { cn } from "../../../utils/utils";
import CountdownLogic from "../../CheckoutPage/CountdownLogic";
import { useCountDownStore } from "../../CheckoutPage/CountdownLogic/useCountDownStore";
import VidalyticsPlayer from "../../VidalyticsPlayer";
import { FirstYoutubePaycheckBtn } from "../FirstYoutubePaycheckBtn";
import "./intro-section.css";
import "./styles.css";

export type IntroSectionProps = {
  hideTimer: boolean;
};

export const IntroSection = ({ hideTimer }: IntroSectionProps) => {
  const { isCountdownExpired } = useCountDownStore();

  return (
    <div className="relative flex flex-col py-[50px] text-center md:px-0 md:py-15">
      {!hideTimer && (
        <CountdownLogic
          initialMinutes={4}
          initialSeconds={30}
          isSaveToStorages={false}
          secondStorageKey="_dsup"
          minuteStorageKey="_dmup"
        >
          {({ minutes, seconds }) => {
            return (
              <div
                className={cn(
                  "container-1200 z-10 flex items-center justify-center gap-3",
                  minutes < 3 ? "flex-col md:flex-row" : "",
                )}
              >
                <div className="flex w-fit items-center justify-center gap-2 rounded-[17px] bg-[#D92D20] py-1 pl-3 pr-3 font-bold text-white">
                  <div className="text-14 font-medium">
                    A button will appear in
                  </div>{" "}
                  <div className="flex min-w-[74px] justify-center rounded-[20px] bg-black px-[15px] py-0">
                    {`${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds
                      }`}
                  </div>
                </div>
              </div>
            );
          }}
        </CountdownLogic>
      )}

      <div className="mx-auto mt-[30px] max-w-3xl px-6 text-28 font-black capitalize text-black md:text-48">
        Please Watch This Short Video In Full While Your Account Is Being
        Created
      </div>

      <div className="flex items-center justify-center">
        <VidalyticsPlayer
          id="wGK4uT_qApLrYCJp"
          className={cn("h-full w-full max-w-2xl md:mx-4 md:py-8 lg:max-w-3xl")}
        />
      </div>

      <div className="px-6">
        <HashLink
          smooth
          to={`${ROUTES.UPSELL1}#seven-figure-launchpad`}
          className={clsx({
            "intro-btn-action-hide": !isCountdownExpired,
            "intro-btn-action": isCountdownExpired,
          })}
        >
          <FirstYoutubePaycheckBtn className="mx-auto mt-[30px] md:mt-12.5" />
        </HashLink>
      </div>

      {/* Background gradients */}
      {isCountdownExpired && (
        <div className="red-radial-gradient absolute bottom-0 left-0 -z-10 h-full w-full"></div>
      )}
    </div>
  );
};
