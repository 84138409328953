/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { purchase } from "../../apis/conversion.api";
import { reEnterPayment } from "../../apis/payment";
import { ROUTES } from "../../constants";
import { useDelayNavigation } from "../../hooks/useDelayNavigation";
import { useAuth } from "../../providers/AuthProvider";
import { CheckoutInputs, CheckoutPayload } from "../../types/type";
import { BillingInformation } from "../CheckoutPage";
import { RedButton } from "../ui";
import { gtag_report_conversion, GTAGEvent } from "../../utils/gtag";

type CheckoutModalProps = {
  amount: number;
  isDownSell?: boolean;
  isOpen: boolean;
  header?: React.ReactNode;
  onClose: () => void;
};

export type CheckoutModalInputs = Pick<
  CheckoutInputs,
  "ccexp" | "ccnumber" | "cvv" | "country" | "zip"
>;

/**
 *
 * @description This component is used to show the checkout modal when a failed payment occurs, this will allow the user to re-enter their payment information
 *
 */
const CheckoutModal = ({
  amount,
  isDownSell,
  isOpen,
  header,
  onClose,
}: CheckoutModalProps) => {
  const {
    control,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm<CheckoutModalInputs>({});

  const [isProcessing, setIsProcessing] = useState(false);

  const toast = useToast();
  const navigate = useDelayNavigation();
  const { checkAuth } = useAuth();
  const location = useLocation();
  const isMembershipPage = location.pathname.includes(ROUTES.MEMBERSHIP);

  /**
   *
   * This function get called when user process to pay with Google Pay or Apple Pay or credit card
   */
  const handleCheckout = async (payload: Partial<CheckoutPayload>) => {
    try {
      setIsProcessing(true);
      const {
        amount,
        platform,
        payment_token,
        payment_intent,
        ccnumber,
        ccexp,
        cvv,
        country,
        zip,
        last4Digit,
      } = payload;

      // Get customer info from form first, if it doesn't have then get from Digital Wallets
      const checkoutPayload: Partial<CheckoutPayload> = {
        amount,
        platform,
        last4Digit,
      };

      checkoutPayload.urlPage = window.location.href;

      // Add payment details to payload based on payment method
      if (payment_token) {
        checkoutPayload.payment_token = payment_token;
      } else if (ccnumber && ccexp && cvv) {
        checkoutPayload.ccnumber = ccnumber.replace(/\s/g, "");
        checkoutPayload.ccexp = ccexp.replace(/ /g, "");
        checkoutPayload.cvv = cvv;
      } else if (payment_intent) {
        checkoutPayload.payment_intent = payment_intent;
      }

      if (country) {
        checkoutPayload.country = country;
      }

      if (zip && country?.toLowerCase() === "us") {
        checkoutPayload.zip = zip;
      }

      if (isDownSell) {
        checkoutPayload.isDownSell = true;
      }

      // API call to process payment
      await reEnterPayment(checkoutPayload);

      if (isMembershipPage) {
        checkAuth();
        onClose();
        // dispatch event to open PurchaseEndlessVideoIdeaModal - success
        window.dispatchEvent(new CustomEvent("viral-open-success-payment"));
      } else {
        // Upsell/Downsell pages
        gtag_report_conversion(GTAGEvent.UPSELL_PURCHASE, {
          url: ROUTES.OTOSU,
          value: amount,
          transaction_id: "" // Add transactionId
        });
      }
    } catch (error: any) {
      toast({
        status: "error",
        position: "top-right",
        description: error?.response?.data?.message,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  // This function get called when user process to pay with credit card
  const handleUnlockAccess = async () => {
    clearErrors(["ccnumber", "zip"]);

    const [ccexp, ccnumber, cvv, country, zip] = getValues([
      "ccexp",
      "ccnumber",
      "cvv",
      "country",
      "zip",
    ]);

    if (!ccnumber || !ccexp || !cvv) {
      setError("ccnumber", {
        type: "manual",
        message: "The card info is required",
      });

      return;
    }

    if (country === "US" && !zip) {
      setError("zip", {
        type: "manual",
        message: "The zip code is required",
      });

      return;
    }

    await handleCheckout({
      amount,
      platform: "nmi",
      ccnumber,
      ccexp,
      cvv,
      country,
      zip,
      last4Digit: ccnumber.slice(-4),
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={{
          base: "calc(100% - 48px)",
          md: "614px",
        }}
        p={{
          base: "30px 12px",
          md: "30px",
        }}
        borderRadius="20px"
      >
        <ModalBody p={0}>
          {header ? (
            header
          ) : (
            <h3 className="mb-[15px] text-center text-26 font-black md:mb-[25px]">
              Your card on file couldn't be processed 😔 <br />
              Please enter your billing info below:
            </h3>
          )}
          <BillingInformation
            amount={amount}
            showSecureCheckout={false}
            handleCheckout={handleCheckout}
            control={control}
            errors={errors}
            isProcessing={isProcessing}
          />

          <div className="px-3">
            <RedButton
              className="my-[15px] h-[43px] !justify-center rounded-[15px] md:my-[25px] md:rounded-[8px]"
              onClick={handleUnlockAccess}
              disabled={isProcessing}
            >
              <p className="text-16px text-center font-bold md:text-18 md:uppercase">
                Unlock access for just ${amount}
              </p>
            </RedButton>
          </div>

          <div className="flex items-center justify-center gap-[11px]">
            <img
              className="max-w-[325px] md:max-w-[411px]"
              src="/images/checkout/secure-checkout.webp"
              alt="secure-checkout"
              draggable={false}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CheckoutModal;
