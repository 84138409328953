import axios from "../libs/axios";
export const sendTags = async (payload: { email: string }) => {
  try {
    const res = await axios.post(
      "user/checkout-start",
      payload,
    );
    return res.data;
  } catch (error) {
    console.error("Error making purchase", error);
  }
};
