import { Button, Image, Input, Text } from "@chakra-ui/react";
import clsx from "clsx";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CheckoutPhoto from "../../assets/images/checkoutPhoto.webp";
import CheckoutPhotoMobile from "../../assets/images/checkoutPhotoMobile.webp";
import { LOCAL_STORAGE_KEY, ROUTES } from "../../constants";
import { useCurrentCountryCode } from "../../hooks";
import { cn, getLocalStorage, handleSendLead } from "../../utils/utils";
import ArrowBackIcon from "../ArrowBackIcon";
import EmailDomainSuggestionEasier from "../EmailDomainSuggestion/controlled";
import PhoneWithCountryField from "../ui/Fields/PhoneWithCountryField";
import { sendTags } from "../../apis/tags.api";
import { fillSalesForm } from "../../apis/user";

export type OptInInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  country?: string;
};

export interface SpotFormSectionProps extends ComponentPropsWithoutRef<"div"> {}

// TODO: Add auto detect locale and select correct flag/phone prefix
const SpotFormSection = (props: SpotFormSectionProps) => {
  const navigate = useNavigate();
  const currentCountryCode = useCurrentCountryCode();
  const [isValidPhoneNumber, setValidPhoneNumber] = useState<boolean>(true);

  const saleUserData = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY.SALE_USER_DATA) ?? "{}",
  );

  const resultPageData = getLocalStorage(LOCAL_STORAGE_KEY.RESULT_PAGE_DATA);

  const methods = useForm<OptInInputs>({
    defaultValues: {
      email: saleUserData?.email || resultPageData?.email || "",
      firstName: saleUserData?.firstName || resultPageData?.firstName || "",
      lastName: saleUserData?.lastName || resultPageData?.lastName || "",
      country:
        saleUserData?.country || resultPageData?.country || currentCountryCode,
      phone: saleUserData?.phone || resultPageData?.phone || "",
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit: SubmitHandler<OptInInputs> = async (data) => {
    await fillSalesForm(data);
    handleSendLead(data.email, data.firstName);
    sendTags({ email: data.email });
    localStorage.setItem(
      LOCAL_STORAGE_KEY.SALE_USER_DATA,
      JSON.stringify(data),
    );
    navigate(ROUTES.CHECKOUT);
  };

  useEffect(() => {
    if (errors.phone) {
      setValidPhoneNumber(false);
    } else {
      setValidPhoneNumber(true);
    }
  }, [errors.phone]);

  return (
    <FormProvider {...methods}>
      <div
        {...props}
        className={cn(
          "flex flex-col items-center justify-between gap-2.5 overflow-hidden rounded-[15px] border border-card-border bg-[#F9FAFB] lg:flex-row",
          props.className,
        )}
      >
        <div className="flex items-center justify-center md:pl-2.5">
          <Image
            className="hidden h-[591px] w-[545px] object-cover md:block"
            src={CheckoutPhoto}
            alt=""
            loading="lazy"
          />
          <Image
            className="mx-0 h-[331px] w-[335px] object-cover md:hidden md:max-w-[545px]"
            src={CheckoutPhotoMobile}
            alt=""
            loading="lazy"
          />
        </div>
        <div
          id="checkoutsteps"
          className="flex w-full flex-col items-center justify-center p-[15px] pb-6 md:mr-[30px] md:w-[604px] lg:pl-0 lg:pt-[30px]"
        >
          <form onSubmit={handleSubmit(onSubmit)} className="md:w-[604px]">
            <div className="mb-4 flex h-[77px] w-full overflow-hidden rounded-[14px] border-2 border-[#E7E7E7] md:mb-[25px] md:h-[98px]">
              <StepItem description="YOUR ACCESS INFO" stepIndex={1} active />
              <StepItem description="GET INSTANT ACCESS" stepIndex={2} />
            </div>

            <Button
              className="mb-[15px] w-full md:mb-7.5"
              background="primary"
              p="8px 16px"
              h="46px"
              textAlign="center"
              borderRadius="12px"
              _hover={{ background: "#a21a10" }}
              _active={{ background: "#a21a10" }}
              type="submit"
            >
              <Text
                className="text-[12px] uppercase md:text-[14px]"
                fontWeight="900"
                // fontSize="14px"
                lineHeight="18px"
                color="#fff"
                mr="10px"
              >
                ⚠️ This is a strictly limited offer
              </Text>
            </Button>

            {/* INPUTS */}
            <div className="mb-5 flex flex-col gap-[10px] md:mb-7.5">
              <div className="w-full">
                <div className="flex flex-row gap-x-[10px] gap-y-[10px]">
                  <div className="flex-1">
                    <Input
                      className=""
                      placeholder="First Name"
                      borderRadius="12px"
                      height={{ base: "44px", md: "70px" }}
                      fontSize={{ base: "16px", md: "20px" }}
                      border="1px solid #EAECF0"
                      backgroundColor="#FFFFFF"
                      _placeholder={{ color: "#888888", fontWeight: "400" }}
                      _focus={{ borderColor: "primary" }}
                      _focusVisible={{ boxShadow: "0 0 0 2px #FECDCA" }}
                      {...register("firstName", {
                        required: "First Name is required",
                      })}
                    />
                    {errors.firstName && (
                      <Text color="red.500" fontSize="14px" mt="3px">
                        {errors.firstName.message}
                      </Text>
                    )}
                  </div>
                  <div className="flex-1">
                    <Input
                      className=""
                      placeholder="Last Name"
                      borderRadius="12px"
                      height={{ base: "44px", md: "70px" }}
                      fontSize={{ base: "16px", md: "20px" }}
                      border="1px solid #EAECF0"
                      backgroundColor="#FFFFFF"
                      _placeholder={{ color: "#888888", fontWeight: "400" }}
                      _focus={{ borderColor: "primary" }}
                      _focusVisible={{ boxShadow: "0 0 0 2px #FECDCA" }}
                      {...register("lastName", {
                        required: "Last Name is required",
                      })}
                    />
                    {errors.lastName && (
                      <Text color="red.500" fontSize="14px" mt="3px">
                        {errors.lastName.message}
                      </Text>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-full">
                <EmailDomainSuggestionEasier
                  fieldName="email"
                  control={control}
                >
                  <Input
                    placeholder="Your Best Email"
                    type="email"
                    borderRadius="12px"
                    height={{ base: "44px", md: "70px" }}
                    fontSize={{ base: "16px", md: "20px" }}
                    border="1px solid #EAECF0"
                    backgroundColor="#FFFFFF"
                    autoComplete="off"
                    _placeholder={{ color: "#888888", fontWeight: "400" }}
                    _focus={{ borderColor: "primary" }}
                    _focusVisible={{ boxShadow: "0 0 0 2px #FECDCA" }}
                    {...register("email", {
                      required: "Email is required",
                    })}
                  />
                </EmailDomainSuggestionEasier>
                {errors.email && (
                  <Text color="red.500" fontSize="14px" mt="3px">
                    {errors.email.message}
                  </Text>
                )}
              </div>

              <div className="flex w-full gap-x-2">
                <PhoneWithCountryField
                  countryName="country"
                  phoneName="phone"
                  placeholder="Phone number (for fast-start updates)"
                  className="w-full"
                  phoneInputProps={{
                    className: "h-11 md:h-17.5 text-16 md:text-18",
                  }}
                  countryInputProps={{
                    containerClassName: cn(
                      "!h-11 md:!h-17.5 md:!w-[160px] !text-16 md:!text-18",
                      "[&>div]:!h-full [&>div]:!w-full",
                      "[&>div>div]:!h-full [&>div>div]:!w-full",
                      "[&_input]:!h-full [&_input]:!w-full",
                      "[&_button]:!h-full [&_button]:!w-full [&_button]:!flex [&_button]:!justify-center",
                    ),
                  }}
                  isValidPhoneNumber={isValidPhoneNumber}
                  setValidPhoneNumber={setValidPhoneNumber}
                />
              </div>
            </div>

            <div className="mb-[12px] flex w-full items-center justify-center md:mb-[16px]">
              <Button
                id="spotformsubmit"
                className="flex w-full items-center justify-center"
                background="primary"
                h="109px"
                borderRadius="12px"
                _hover={{ background: "#a21a10" }}
                _active={{ background: "#a21a10" }}
                type="submit"
                display="block"
              >
                <p
                  className="flex items-center justify-center text-20 font-black text-white md:text-32"
                  style={{
                    textShadow: "rgba(0, 0, 0, 0.15) 1px 1px 1px",
                  }}
                >
                  YES, SAVE MY SPOT!{" "}
                  <ArrowBackIcon color="white" size={24} className="ml-2" />
                </p>

                <p className="mt-2 whitespace-normal text-wrap text-[14px] text-[#FFBEB9] md:text-[16px]">
                  I WANT INSTANT ACCESS TO THE FAST-START NOW!{" "}
                </p>
              </Button>
            </div>

            <div className="flex items-center justify-center text-wrap text-left">
              <p className="text-[12px] text-[#888888] md:text-[16px]">
                *Due to video-hosting bandwidth limitations, we can only enroll
                a limited number of new members. To secure your spot for this
                exclusive offer, please enter your contact details so we can
                hold your spot.
              </p>
            </div>
          </form>
        </div>
      </div>
    </FormProvider>
  );
};

interface StepItemProps extends ComponentPropsWithoutRef<"div"> {
  stepIndex: number;
  label?: string;
  description: string;
  active?: boolean;
}

const StepItem = ({
  className,
  stepIndex,
  label = "STEP",
  description,
  active,
  ...props
}: StepItemProps) => {
  return (
    <div
      {...props}
      className={cn(
        "flex flex-1 gap-2 md:gap-[15px]",
        "flex items-center justify-start px-[10px] sm:p-[15px] md:p-5",
        active ? "bg-spotform-steps-bgActive" : "bg-spotform-steps-bg",
        className,
      )}
    >
      <h2
        className={clsx(
          "size-[32.8px] shrink-0 rounded-xl text-26 font-bold md:size-[56px] md:rounded-[14px]",
          "flex items-center justify-center",
          active
            ? "bg-spotform-steps-bgItemActive text-white"
            : "bg-spotform-steps-bgItem text-black",
        )}
      >
        {stepIndex}
      </h2>

      <div className="flex flex-col gap-0 md:gap-1">
        <h2 className="text-18 font-bold md:text-25">
          {label}: {stepIndex}
        </h2>
        <p
          className={clsx(
            "text-12 font-medium md:text-16",
            active ? "text-spotform-steps-subtext" : "text-[#A4A4A4]",
          )}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default SpotFormSection;
