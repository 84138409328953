import { Box } from "@chakra-ui/react";
import { debounce, set } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { unlockNextVideo, updateVideoProgress } from "../../apis/user";
import { kThreshold, ROUTES } from "../../constants";
import { VideoType } from "../../pages/membership";
import { useAuth } from "../../providers/AuthProvider";
import { Dict } from "../../types/type";
import { cn, getLocalStorage, setLocalStorage } from "../../utils/utils";
import CTAButton from "../CTAButton";
import FastStartPopUp from "../FastStartPopUp";
import RequestNextDayVideo from "../RequestNextDayVideo";
import WistiaPlayer, { VideoProgress } from "../WistiaPlayer";
import FastStartHeadLine from "./FastStartHeadline";

type FastStartVideoProps = {
  selectedVideo: VideoType;
  activeVideoIndex: number;
  isDisplayQualifyBtn?: boolean;
};

const FastStartMainVideo = ({
  selectedVideo,
  activeVideoIndex,
  isDisplayQualifyBtn = false,
}: FastStartVideoProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showUnlockNextDay, setShowUnlockNextDay] = useState(false);
  const [showQualifyButton, setShowQualifyButton] = useState(false);
  const [currentUpdatedProgress, setCurrentUpdatedProgress] = useState({
    percent: 0,
    progress: 0,
  });

  const { user } = useAuth();
  const { watchedVideos } = user || {};

  const initialValue: VideoProgress = { seconds: 0, percent: 0, duration: 1 };

  useEffect(() => {
    // if any video has percentWatched over the threshold, show qualify button forever
    const videos = watchedVideos ?? [];

    if (Array.isArray(videos)) {
      const any = videos.find(
        (x) => x.percent > kThreshold.qualifyButton.percentWatched,
      );
      if (any) setShowQualifyButton(true);
    }

    document.body.style.removeProperty("overflow");
    window.scrollTo(0, 0);

    localStorage.removeItem("firstConditionTime");

    setShowQualifyButton(isDisplayQualifyBtn);
  }, []);

  const onWistiaUpdate = debounce(async (video: VideoProgress) => {
    const videoPercent = video.percent;
    const videoProgress = video.seconds / video.duration;

    if (!isDisplayQualifyBtn) {
      if (activeVideoIndex <= 1 && Math.floor(videoPercent) >= 95) {
        setShowQualifyButton(true);
      }

      if (activeVideoIndex >= 1 && Math.floor(videoPercent) >= 50) {
        let firstConditionTime = getLocalStorage("firstConditionTime");

        if (!firstConditionTime) {
          firstConditionTime = new Date().getTime();
          setLocalStorage("firstConditionTime", firstConditionTime);
        }

        const now = new Date().getTime();
        // if (now > firstConditionTime + 5 * 1000) {
        if (now > firstConditionTime + 12 * 60 * 60 * 1000) {
          setShowQualifyButton(true);
        }
      }
    }

    if (
      videoPercent !== currentUpdatedProgress.percent ||
      videoProgress !== currentUpdatedProgress.progress
    ) {
      const { data } = await updateVideoProgress({
        percent: videoPercent,
        progress: videoProgress,
        videoIndex: activeVideoIndex,
      });

      setCurrentUpdatedProgress({
        percent: data?.metadata?.percent || videoPercent,
        progress: data?.metadata?.progress || videoProgress,
      });
    }
  }, 1000);

  const handleClickRequestNextDayVideo = async () => {
    try {
      await unlockNextVideo();
    } catch (error) {
      console.log("Error requesting next day video", error);
    }
  };

  const handleCloseRequestNextDayVideo = () => {
    const requestedVideo: Dict = getLocalStorage("requestedVideo") || {};
    requestedVideo[activeVideoIndex + 1] = true;
    setLocalStorage("requestedVideo", requestedVideo);
    setShowUnlockNextDay(false);
  };

  return (
    <Box>
      <FastStartHeadLine text={selectedVideo.title} />

      <Box
        className={cn(
          `mb-[15px] min-[1440px]:mb-[20px]`,
          showUnlockNextDay && "!mb-0",
        )}
      >
        <WistiaPlayer
          isScrollToTop
          id={selectedVideo.videoId}
          initialValue={initialValue}
          onWistiaUpdate={onWistiaUpdate}
          activeVideoIndex={activeVideoIndex}
        />
      </Box>

      {showUnlockNextDay && (
        <RequestNextDayVideo
          onClick={handleClickRequestNextDayVideo}
          onClose={handleCloseRequestNextDayVideo}
        />
      )}

      {showQualifyButton && (
        <CTAButton onClick={() => navigate(ROUTES.SEE_IF_YOU_QUALIFY)} />
      )}

      <FastStartPopUp isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  );
};

export default FastStartMainVideo;
