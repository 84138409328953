import {
  Guarantee,
  IntroSection,
  StoryTellingSection,
} from "../components/DownSell";
import RapidViralSecrets from "../components/DownSell/RapidViralSecrets";
import { CloseWarning } from "../components/UpSell";
import Footer from "../components/shared/Footer";

const DownSellPage = () => {
  return (
    <>
      <CloseWarning />
      <IntroSection />
      <StoryTellingSection />
      <RapidViralSecrets />
      <Guarantee />
      <Footer />
    </> 
  );
};

export default DownSellPage;
