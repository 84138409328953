/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { UserIcon } from "../../assets/icons";
import { CheckoutInputs } from "../../types/type";
import { updateSaleUserDataLocalStorage } from "../../utils/localStorage";
import {
  cn,
  getIPAddress,
  getOSInfo,
  sendPaymentInfor,
  sendPurchase,
} from "../../utils/utils";
import EmailDomainSuggestion from "../EmailDomainSuggestion";
import InputErrorMessage from "../InputErrorMessage";
import {
  CountryInput,
  GraySection,
  Input,
  PHONE_NUMBER_REGEX,
  PhoneNumberInput,
} from "../ui";
import { EMAIL_REGEX, EmailInput } from "../ui/Input/EmailInput";

type CustomerInformationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<CheckoutInputs, any>;
  errors: FieldErrors<CheckoutInputs>;
  watch: UseFormWatch<CheckoutInputs>;
  setValue: UseFormSetValue<CheckoutInputs>;
  amount?: number;
};

const onChangeField = (name: string, value: string) => {
  updateSaleUserDataLocalStorage({ [name]: value });
};

export const CustomerInformation = ({
  control,
  errors,
  amount,
}: CustomerInformationProps) => {
  const firstName = useWatch({ control, name: "firstName" });
  const lastName = useWatch({ control, name: "lastName" });
  const email = useWatch({ control, name: "email" });
  const phone = useWatch({ control, name: "phone" });
  const zip = useWatch({ control, name: "zip" });

  const handleSendConversion = async () => {
    sendPaymentInfor(
      await getIPAddress(),
      getOSInfo(),
      firstName || "",
      lastName || "",
      phone || "",
      zip || "",
      email || "",
      window.location.href,
    );
    sendPurchase(
      await getIPAddress(),
      getOSInfo(),
      firstName || "",
      lastName || "",
      phone || "",
      zip || "",
      email || "",
      window.location.href,
      String(amount),
    );
  };
  useEffect(() => {
    if (firstName && lastName && email && phone) {
      console.log("All fields have value");
      handleSendConversion();
    }
  }, [firstName, lastName, email, phone]);

  return (
    <>
      <GraySection label="Customer Information" icon={<UserIcon />}>
        <div className="pt-[25px]">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-15 font-medium capitalize">First name</p>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: "First Name is required" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e);
                      onChangeField("firstName", e.target.value);
                    }}
                    className="mt-[6px] w-full"
                    placeholder="First Name"
                  />
                )}
              />
              {errors.firstName && (
                <InputErrorMessage
                  message={errors.firstName.message as string}
                />
              )}
            </div>

            <div>
              <p className="text-15 font-medium capitalize">Last name</p>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: "Last Name is required" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e);
                      onChangeField("lastName", e.target.value);
                    }}
                    className="mt-[6px] w-full"
                    placeholder="Last Name"
                  />
                )}
              />

              {errors.lastName && (
                <InputErrorMessage
                  message={errors.lastName.message as string}
                />
              )}
            </div>
          </div>

          <CustomerEmailInput />

          <CustomerPhoneInput />
        </div>
      </GraySection>
    </>
  );
};

const CustomerPhoneInput = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CheckoutInputs>();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValidPhoneNumber, setValidPhoneNumber] = useState<boolean>(true);

  return (
    <div className="mt-4">
      <p className="text-15 font-medium capitalize">Phone</p>
      <div className="mt-[6px] flex gap-2">
        <Controller
          name="country"
          control={control}
          render={({ field }) => {
            return (
              <CountryInput
                countryCode={field.value}
                {...field}
                onChange={(value, dialCode) => {
                  field.onChange(value);
                  onChangeField("country", value);
                  onChangeField("dialCode", dialCode);
                }}
                showArrow
                containerClassName={cn(
                  "max-w-[100px] w-full",
                  "[&_.iti--allow-dropdown]:w-full [&_.iti__country-container]:!w-full",
                  "[&_.iti--allow-dropdown]:h-full [&_.iti__country-container]:!h-full",
                  "[&_.select-country-code]:w-full",
                  "[&_input]:!w-full",
                  "w-full",
                )}
              />
            );
          }}
        />
        <Controller
          name="phone"
          control={control}
          rules={{
            required: "Phone is required",
            pattern: PHONE_NUMBER_REGEX,
            value: phoneNumber,
            onChange: (e) => {
              setPhoneNumber(e.target.value);
              setValidPhoneNumber(PHONE_NUMBER_REGEX.test(e.target.value));
              onChangeField("phone", e.target.value);
            },
          }}
          render={({ field }) => (
            <PhoneNumberInput
              {...field}
              placeholder="Phone number (for fast-start updates)"
              isValidPhoneNumber={isValidPhoneNumber}
            />
          )}
        />
      </div>
      {errors.phone && (
        <InputErrorMessage message={errors.phone.message as string} />
      )}

      {/* <PhoneFocusing /> */}
    </div>
  );
};

const PhoneFocusing = () => {
  const { control } = useFormContext<CheckoutInputs>();
  const [country] = useWatch({ control, name: ["country"] });

  useEffect(() => {
    setTimeout(() => {
      document.querySelector<HTMLElement>("#input_phone")?.focus();
    }, 100);
  }, [country]);

  return null;
};

export const CustomerEmailInput = () => {
  const methods = useFormContext<CheckoutInputs>();
  const [isValidEmail, setValidEmail] = useState<boolean>(true);
  const [showEmailSuggestions, setShowEmailSuggestions] = useState(false);
  const emailWatch = useWatch({ control: methods.control, name: "email" });
  const errors = methods.formState.errors;

  return (
    <div className="mt-4">
      <p className="text-15 font-medium capitalize">Email Address</p>
      <Controller
        name="email"
        control={methods.control}
        rules={{
          required: "Email is required",
          pattern: EMAIL_REGEX,
          onChange: (e) => {
            setValidEmail(EMAIL_REGEX.test(e.target.value));
            setShowEmailSuggestions(
              !!e.target.value && !e.target.value?.includes("@"),
            );
            onChangeField("email", e.target.value);
          },
        }}
        render={({ field }) => (
          <EmailInput
            {...field}
            className="mt-[6px] w-full bg-white"
            placeholder="Email"
            isValidEmail={isValidEmail}
            onFocus={() => {
              setShowEmailSuggestions(
                !!emailWatch && !emailWatch?.includes("@"),
              );
            }}
          />
        )}
      />

      {errors.email && (
        <InputErrorMessage message={errors.email.message as string} />
      )}

      <div className="relative">
        {showEmailSuggestions}
        {showEmailSuggestions && (
          <EmailDomainSuggestion
            email={emailWatch}
            onEmailSuggestionClick={(email) => {
              methods.setValue("email", email);
              setValidEmail(EMAIL_REGEX.test(email));
              setShowEmailSuggestions(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
